// UserPersona.js

import React from "react";
import styles from "./userPersona.module.scss";
import MainLayout from './layout/mainLayout';
import { Col, Container, Row } from "react-bootstrap";
// import Points from "../../src/assets/icons/points";
import SideImage1 from "../../src/assets/images/image 6.png";
import SideImage2 from "../../src/assets/images/professor.png";
import SideImage3 from "../../src/assets/images/school-teacher.png";
import FillDots from "../../src/assets/icons/fillDots";
import GreenDots from "../../src/assets/icons/greenDots";
import Lineone from "../../src/assets/icons/lineone";

const UserPersona = () => {
  return (
    <MainLayout>
      <Container className={`${styles.wrapper} py-5`}>
        {/* <div className={styles.userPersonaWrapper}>
          <h1>User Persona</h1>
        </div> */}
        <Row>
          <Col xl="6" data-aos="fade-up" data-aos-duration="500">
            <div className={styles.SideImage1}>
              <span className={styles.fillDots}>
                <FillDots color={"#473BF0"} />
              </span>
              <span className={styles.greenDots}>
                <GreenDots color={"#68D585"} />
              </span>
              <span className={styles.line}>
                <Lineone />
              </span>
              <span className={styles.borderBg}></span>
              <img src={SideImage1} alt="img" />
            </div>
          </Col>
          <Col className="ps-md-5 pt-md-5" style={{marginTop:'-30px'}}>
            <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h5`}>Company</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="550">
              <p className="h2">Training and Support</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-6"
                style={{
                  marginTop: "10px",
                  textIndent: "20px",
                  textAlign: "justify"
                }}
              >
                Meet <strong>Tom</strong>, a Learning and Development Manager at a rapidly growing
                tech company. Tom&apos;s team is responsible for creating and
                maintaining help documents and product training materials for both
                internal employees and customers. With the company&apos;s
                expanding product line and frequent updates, Tom finds himself
                struggling to keep up with the demand for fresh, engaging content.
              </p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-6"
                style={{ textAlign: "justify" }}
              >
                Tom has always known that video content is more engaging and
                effective than traditional text-based documentation. However,
                producing high-quality videos has been time-consuming and
                expensive, often requiring outsourcing to professional video
                production teams. With Feedio, Tom can now create
                professional-looking videos in-house, quickly and efficiently.
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="ps-md-5 pt-md-5 fs-6" style={{ textAlign: "justify" }}>
            {/* <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h4`}>Challenges</p>
            </Row> */}

            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                What used to take weeks now takes mere hours. Tom is amazed at how
                Feedio can turn complex product features into clear,
                concise video explanations. The platform&apos;s vast library of
                templates, icons, and animations allows him to create visually
                appealing content that captures and maintains viewer attention.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                As Tom shares the new video-based help documents and training
                materials with his colleagues and customers, the feedback is
                overwhelmingly positive. Employees are learning new features
                faster, and customer support inquiries have decreased
                significantly. With Feedio, Tom has not only streamlined
                his team&apos;s workflow but also elevated the quality and
                effectiveness of their output. He&apos;s now seen as an innovator
                within the company, driving digital transformation in learning and
                development.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                In a world where visual content reigns supreme, Feedio
                empowers professionals like Tom to create impactful, scalable, and
                cost-effective video content for help documents and product
                training. It&apos;s not just a tool; it&apos;s a game-changer that
                turns complex information into engaging, easily digestible video
                content, saving time, reducing costs, and improving learning
                outcomes across the board.
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            className="ps-xl-5 pt-xl-5 ps-lg-5 pt-lg-5 ps-md-5 ps-4"
            data-aos="zoom-in-right"
            data-aos-duration="600"
          >
            <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h5`}>Professor</p>
            </Row>
            <p className="h3" data-aos="fade-up" data-aos-duration="550">
              University Professionals
            </p>
            <p
              className="fw-medium lh-lg fs-6"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{
                marginTop: "10px",
                textIndent: "20px",
                textAlign: "justify"
              }}
            >
              Meet <strong>Dr.Sarah Thompson</strong>, a dedicated biology professor at a
              university. For years, she&apos;s been searching for ways to make
              her lectures more engaging and accessible to her diverse student
              body. Despite her passion for teaching, she often struggles to
              convey complex biological processes in a way that resonates with all
              her students.
            </p>
            <p
              className="fw-medium lh-lg fs-6"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{
                textAlign: "justify"
              }}
            >
              One day, a colleague introduces her to Feedio,
              a cutting-edge video generation SaaS product designed specifically
              for educators. Intrigued, Dr. Thompson decides to give it a try. As
              she logs into Feedio for the first time, she&apos;s
              immediately impressed by its user-friendly interface. With just a
              few clicks, she&apos;s able to input her lecture notes on cell
              division. The AI-powered platform quickly transforms her text into a
              visually stunning video explaining mitosis and others in detail.
            </p>
          </Col>
          <Col xl="6" style={{marginTop: "70px"}}>
            <div
              className={styles.SideImage2}
              data-aos="zoom-in"
              data-aos-duration="500"
            >
              <span className={styles.fillDots}>
                <FillDots color={"#FDBA09"} />
              </span>
              <span className={styles.greenDots}>
                <GreenDots color={"#3E66F3"} />
              </span>
              <span className={styles.borderBg}></span>
              <img src={SideImage2} alt="img" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="ps-md-5 pt-md-5 fs-6" style={{ textAlign: "justify" }}>
            {/* <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h4`}>Challenges</p>
            </Row> */}

            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                Dr.Thompson is amazed at how Feedio has brought her lecture to
                life. The video seamlessly integrates key concepts, terminology, and
                visual aids, making the complex process of cell division easier to
                understand and remember. Excited about the possibilities, Dr.
                Thompson incorporates the video into her next lecture. The results
                are immediate and striking. Her students are more engaged than ever
                before, asking insightful questions and demonstrating a deeper
                understanding of the material. But Feedio&apos;s benefits
                extend beyond the classroom. Dr. Thompson can easily share the
                videos through the university&apos;s learning management system,
                allowing students to review the material at their own pace. This
                proves especially valuable for students who miss class or need extra
                reinforcement.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                As the semester progresses, Dr. Thompson discovers
                she&apos;s saving hours of preparation time. Instead of struggling
                to create her own visual aids or searching for appropriate videos
                online, she can quickly generate custom content for each lecture.
                This allows her to focus more on interaction with students and
                developing innovative teaching strategies. The impact of
                Feedio on Dr. Thompson&apos;s teaching is so significant
                that her colleagues take notice. Soon, she&apos;s leading workshops
                on campus, showing other professors how to leverage this powerful
                tool to enhance their own teaching methods.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                For Dr. Thompson,
                Feedio has revolutionized her approach to education.
                It&apos;s not just about creating videos; it&apos;s about unlocking
                new ways to connect with students, explain complex ideas, and
                inspire a love for learning.
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xl="6" data-aos="fade-up" data-aos-duration="500" style={{marginTop: "70px"}}>
            <div className={styles.SideImage3}>
              <span className={styles.fillDots}>
                <FillDots color={"#473BF0"} />
              </span>
              <span className={styles.greenDots}>
                <GreenDots color={"#68D585"} />
              </span>
              <span className={styles.line}>
                <Lineone />
              </span>
              <span className={styles.borderBg}></span>
              <img src={SideImage3} alt="img" />
            </div>
          </Col>
          <Col className="ps-md-5 pt-md-5">
            <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h5`}>School</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="550">
              <p className="h2">Teaching Professionals</p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-6"
                style={{
                  marginTop: "10px",
                  textIndent: "20px",
                  textAlign: "justify"
                }}
              >
                Meet <strong>Kandan</strong>, a dedicated high school science teacher who&apos;s always looking for innovative ways to engage his students. Like many educators, Kandan struggles to find time to create compelling visual content that brings complex scientific concepts to life. That&apos;s where our video generation platform comes in.
              </p>
            </Row>
            <Row data-aos="fade-up" data-aos-duration="600">
              <p
                className="fw-medium lh-lg fs-6"
                style={{ textAlign: "justify" }}
              >
                Imagine Kandan preparing for his upcoming unit on cellular biology. Instead of spending hours searching for the perfect video or struggling with complicated animation software, he simply logs into our user-friendly platform. With just a few clicks, he inputs his lesson objectives and key concepts.
              </p>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="ps-md-5 pt-md-5 fs-6" style={{ textAlign: "justify" }}>
            {/* <Row data-aos="fade-up" data-aos-duration="500">
              <p className={`${styles.fontColor} h4`}>Challenges</p>
            </Row> */}

            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                Our AI-powered system quickly generates visually stunning, scientifically accurate images and content on the cell&apos;s inner workings. The video seamlessly illustrates the intricate processes of mitosis and meiosis, concepts that students often find challenging to grasp from textbooks alone. The next day, Kandan presents the video to his class. He watches as his students&apos; eyes light up with understanding. The dynamic visuals and clear explanations help even his most struggling students grasp these complex ideas. After class, several students approach him, excited to learn more about cellular biology.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                With our video generation tool, Kandan has not only saved countless hours of preparation time but has also dramatically improved student engagement and comprehension. He can now focus more on what he does best – inspiring young minds and fostering a love for science. As word spreads about Kandan&apos;s engaging lessons, other teachers at his school become interested. Soon, the entire science department is using our platform to create captivating videos for various subjects, from chemistry experiments to physics concepts.
              </p>
            </Row>
            <Row
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ textAlign: "justify" }}
            >
              <p className="fw-medium lh-lg fs-6">
                By empowering teachers like Kandan with cutting-edge technology, we&apos;re not just saving time – we&apos;re revolutionizing the way students learn, making complex subjects accessible and exciting for the next generation of scientists, engineers, and innovators.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default UserPersona;







