import React, { useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Tick from '../../assets/icons/tick';
import RightArrow from '../../assets/icons/rightArrow';
import styles from './price.module.scss';
import { toast } from 'react-toastify';

const Price = () => {
  const [isModal, setModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e?.preventDefault();
    toast.success("Form Submitted!");
    setModalOpen(false);
  };

  const FormModal = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Contact Us
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="name" className="col-form-label" label="Name">
                <Form.Control
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Your name"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="phoneNo" className="col-form-label" label="Phone number">
                <Form.Control
                  type="number"
                  name="phoneNo"
                  id="phoneNo"
                  placeholder="Your Phone number"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="email" className="col-form-label" label="Email">
                <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your email"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
            <div className="mb-3">
              <Form.FloatingLabel htmlFor="message" className="col-form-label" label="Message">
                <Form.Control
                  as="textarea"
                  name="message"
                  id="message"
                  placeholder="Message"
                  required
                  className='shadow-none'
                />
              </Form.FloatingLabel>
            </div>
          </Form>
          <div className='d-flex justify-content-end gap-2'>
            <Button className={`${styles.cusbtnOne} mb-3`} onClick={props.onHide} variant=''>Close</Button>
            <Button className={`${styles.cusbtn} mb-3`} variant='' onClick={handleSubmit}>Submit</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const FreePlanCard = () => {
    return (
      <Card className={`${styles.priceCard} shadow-1 my-md-4 my-3`}>
        <Card.Body>
          <Card.Title className='text-center'>Free Plan</Card.Title>
          <Card.Title className='text-center pb-2'>
            <span className='text-black-50' > 10 Minutes Video </span ><span className='text-black-50'>/ Month</span>
          </Card.Title>
          <hr className="border-color " />
          {/* <Card.Text className='pt-3'><span className='me-2'><Tick /></span></Card.Text> */}
          <Card.Text><span className='me-2'><Tick /></span>Access to basic video creation tools video editor, screen recorder, basic templates.</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Limited access to stock media images, music, etc.</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Watermark on exported videos</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Community support</Card.Text>
          <div className='d-flex justify-content-center mt-4 mb-2'>
            <Button className={`${styles.cusbtn}`} variant='' href='https://app.feedio.ai/'>Free trial<span className='ms-2'><RightArrow /></span></Button>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const PremiumPlanCard = () => {
    return (
      <Card className={`${styles.priceCard} shadow-1 my-md-4 my-3`}>
        <Card.Body>
          <Card.Title className='text-center'>Starter Plan</Card.Title>
          <Card.Title className='text-center pb-2'>
            <span className='text-black-50'><b>$15</b>/month or <b>$150</b>/annual</span><span className='text-black-50'></span>
          </Card.Title>
          <hr className="border-color " />
          <Card.Text><span className='me-2'><Tick /></span>Up to 60 minutes of generated video per month</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Access to a larger library of stock media</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>No watermark on exported videos</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Premium support email, chat</Card.Text>
          <div className='d-flex justify-content-center mt-4 mb-2'>
            <Button className={`${styles.cusbtn}`} variant=''>Subscribe<span className='ms-2'><RightArrow /></span></Button>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const EnterprisePlanCard = () => {
    return (
      <Card className={`${styles.priceCard} shadow-1 my-md-4 my-3`}>
        <Card.Body>
          <Card.Title className='text-center'>Professional Plan</Card.Title>
          <Card.Title className='text-center pb-2'>
            <span className='text-black-50'><b>$35</b>/month or <b>$350</b>/annual</span><span className='text-black-50'></span>
          </Card.Title>
          <hr className="border-color " />
          {/* <Card.Text className='pt-3'><span className='me-2'><Tick /></span>Price: $35 per month or $350 <b>annually</b></Card.Text> */}
          <Card.Text><span className='me-2'><Tick /></span>Up to 180 minutes of generated video per month</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>All features from the Starter Plan</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Access to a vast library of premium stock media</Card.Text>
          <Card.Text><span className='me-2'><Tick /></span>Advanced video analytics and insights in creation</Card.Text>
          <div className='d-flex justify-content-center mt-4 mb-2'>
            <Button className={`${styles.cusbtn}`} variant=''>Subscribe<span className='ms-2'><RightArrow /></span></Button>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Container>
        <p className='h1 text-center pt-3' data-aos="fade-up" data-aos-duration="700">Pricing</p>
        <p className='h6 text-center py-4' data-aos="fade-up" data-aos-duration="780">
          Our pricing structure is designed to be transparent and flexible, ensuring affordability while catering to diverse needs and budgets.
        </p>
        <Row>
          <Col md={12} xl={4} className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
            <FreePlanCard />
          </Col>
          <Col md={12} xl={4} className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
            <PremiumPlanCard />
          </Col>
          <Col md={12} xl={4} className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
            <EnterprisePlanCard />
          </Col>
        </Row>
        <p className='h6 text-center py-4' data-aos="zoom-in" data-aos-duration="800">Custom solutions for enterprise clients</p>
        <div className='d-flex justify-content-center' data-aos="zoom-in" data-aos-duration="800">
          <>
            <Button className={`${styles.cusbtn} mb-3`} variant='' onClick={() => setModalOpen(true)}>Contact Us</Button>
            <FormModal show={isModal} onHide={() => setModalOpen(false)} />
          </>
        </div>
      </Container>
    </div>
  );
};

export default Price;
