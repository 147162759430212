import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import Insta from '../../assets/icons/insta';
import Twitter from '../../assets/icons/twitter';
import FaceBook from '../../assets/icons/fb';
import YouTube from '../../assets/icons/yt';
import Ticktok from '../../assets/icons/ticktok';


const Footer = () => {
  return (
    <div>
      <Container className='pt-3' style={{backgroundColor:'#212529'}}>
        <p className='h3 text-center text-light'>Feedio.ai</p>
        <div className='d-md-flex justify-content-center gap-5'>
          <Nav.Link href="#home" className='fw-semibold text-light py-3'>Home</Nav.Link>
          <Nav.Link href="#solution" className='fw-semibold text-light py-3'>About us</Nav.Link>
          <Nav.Link href="#audience" className='fw-semibold text-light py-3'>Services</Nav.Link>
          <Nav.Link href="#footer" className='fw-semibold text-light py-3'>Contact us</Nav.Link>
          <Nav.Link href="http://app.feedio.ai/" className='fw-semibold text-light py-3'>Login</Nav.Link>
        </div>
        <div className='d-flex justify-content-center gap-md-3 gap-3 pb-3'>
          <p className='h6 text-center pt-md-4'><Insta/></p>
          <p className='h6 text-center pt-md-4'><Twitter/></p>
          <p className='h6 text-center pt-md-4'><FaceBook/></p>
          <p className='h6 text-center pt-md-4'><YouTube/></p>
          <p className='h6 text-center pt-md-4'><Ticktok/></p>
        </div>
      

      </Container>
      <div className='d-flex justify-content-center pb-3 bg-body-tertiary'>
        <p className='h6 text-center pt-4 text-black'>© Feedio Ai, all rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
